import React from 'react';
import Header from '@components/layout/header';
import Main from '@components/layout/main';
import Section from '@components/layout/section';
import EventsSection from '@components/sections/events';
import AboutSection from '@components/sections/about';
import SupportersSection from '@components/sections/supporters';
import NewsSection from '@components/sections/news';
import PressSection from '@components/sections/press';
import BlogSection from '@components/sections/blog';
import Meta from '@components/layout/meta';

function Page() {
    return (
        <>
            <Meta
                title='Bündnis #noIAA'
                description='Klima vor Profit - Mobilitätswende jetzt! Protest hat die Inter­nationale Automobil­ausstellung (IAA) aus Frankfurt vertrieben. Daran knüpfen wir als Münchner Bündnis an.'
                type='website'
                path='/'
            />
            <Header
                links={[
                    {title: 'Das Bündnis', id: 'about-us'},
                    {title: 'Veranstaltungen', id: 'events'},
                    {title: 'Unterstützer*innen', id: 'supporters'},
                    {title: 'In der Presse', id: 'press'},
                ]}
            />
            <Main noBottom>
                <Section id='about-us'>
                    <AboutSection variant='regular' />
                    <BlogSection />
                </Section>
                <Section id='events' dark>
                    <EventsSection />
                    <NewsSection />
                </Section>
                <Section id='supporters'>
                    <SupportersSection />
                </Section>
                <Section id='press' dark>
                    <PressSection />
                </Section>
            </Main>
        </>
    );
}

export default Page;
